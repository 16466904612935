import React from 'react'
import classNames from 'classnames'

import { colors } from '../utils/styles'
import DarkContainerContext from '../contexts/DarkContext'

const Container = ({ darkBg = false, children, className, ...props }) => (
  <div {...props} className={classNames(className, { darkBg })}>
    <DarkContainerContext.Provider value={{ darkBg }}>
      {children}
    </DarkContainerContext.Provider>

    <style jsx>
      {`
        .darkBg {
          background-color: ${colors.darkest};
          color: ${colors.lightest};
        }
      `}
    </style>
  </div>
)

export default Container
