import React from 'react'
import Container from '../components/Container'
import { Button, PrimaryButton } from '../components/shared/Buttons'

class Page extends React.Component {
  render() {
    return (
      <div>
        <div>
          <h1>h1</h1>
          <h2>h2</h2>
          <h3>h3</h3>
          <h4>h4</h4>
          <h5>h5</h5>
          <h6>h6</h6>
          <p>Paragraph</p>
          <div>
            <span>Span</span>
          </div>
          <Button style={{ marginTop: '40px', width: 'fit-content' }}>
            <span>Shop plants</span>
          </Button>
          <PrimaryButton style={{ marginTop: '40px', width: 'fit-content' }}>
            <span>PrimaryButton</span>
          </PrimaryButton>
        </div>
        <div className="mt24">
          <Container darkBg>
            <h1>h1</h1>
            <h2>h2</h2>
            <h3>h3</h3>
            <h4>h4</h4>
            <h5>h5</h5>
            <h6>h6</h6>
            <p>Paragraph</p>
            <div>
              <span>Span</span>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default Page
